<template>
  <div v-if="showAnything" :class="classes">
    <div v-if="title" class="title" v-text="title"/>
    <div v-if="descr" class="descr" v-text="descr"/>
    <div class="-fc">
      <div v-if="computedShowRequires">
        <Mutations :list="deal.requires" :target-object="targetObject" :research-id="researchId"
                   display="info" :concept-display="conceptDisplay"/>
      </div>
      <div v-if="computedShowUses">
        <Mutations :list="deal.uses" :target-object="targetObject"
                   display="info" :concept-display="conceptDisplay"/>
      </div>
      <div v-if="computedShowCosts">
        <Mutations :list="deal.costs" :target-object="targetObject"
                   display="attention" :concept-display="conceptDisplay"/>
      </div>
      <div v-if="computedShowGains">
        <Mutations :list="deal.gains" :target-object="targetObject"
                   display="gain" :concept-display="conceptDisplay"/>
      </div>
      <div v-if="forgingVal" class="mutations">
        <Mutation :mutation="{p1: 'forging', val: forgingVal}"
                  display="info" :concept-display="conceptDisplay"/>
      </div>
      <div v-if="cooldown" class="mutations">
        <Mutation :mutation="{p1: 'timed', val: utils.millisToTime(cooldown * 1000)}"
                  display="info" :concept-display="conceptDisplay"/>
      </div>
      <Mutexes :mutex-root="mutexRoot" :infosCallback="updateMutexInfos"/>
    </div>
  </div>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import Mutation from '@/components/utils/Mutation';
import Mutexes from '@/components/Mutexes';
import {utils} from '@/helpers';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      title: {type: String},
      descr: {type: String},
      targetObject: {type: Object},
      deal: {type: Object, required: true},
      researchId: {type: String},
      cooldown: {type: Number},
      forgingVal: {type: Number},
      mutexRoot: {type: Object, default: () => ({})},
    }
);

const standardClasses = ['concept', '-content-only'];
const targetedClasses = standardClasses.concat('-targeted');

const { mutexInfos, updateMutexInfos } = compositions.mux();

const conceptDisplay = computed(() => props.targetObject === null ? 'valueless' : 'full');

const computedShowRequires = computed(() => utils.hasItems(props.deal.requires) || !!props.researchId);

const computedShowUses = computed(() => utils.hasItems(props.deal.uses));

const computedShowCosts = computed(() => utils.hasItems(props.deal.costs));

const computedShowGains = computed(() => utils.hasItems(props.deal.gains));

const showAnything = computed(() =>
                                  computedShowRequires.value || computedShowUses.value ||
                                  computedShowCosts.value || computedShowGains.value ||
                                  props.cooldown || props.forgingVal ||
                                  (!mutexInfos.value || mutexInfos.value.length));

const classes = computed(() => typeof props.targetObject === 'undefined' ? standardClasses : targetedClasses);
</script>

<style>
.concept .title {
  margin-top: 3px;
  font-weight: bold;
}

.concept .descr {
  font-style: italic;
  margin-bottom: 3px;
  line-height: 1rem;
}

@media screen and (max-width: 540px) {
  .concept .descr {
    font-size: 0.9rem;
    line-height: 0.85rem;
  }
}
</style>