<template>
  <div v-if="modulesPresent" class="list">
    <Module v-for="module in playerModules" :key="module._id" :module="module"/>
  </div>
</template>

<script setup>
import Module from '@/components/Module';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {api} from '@/api';
import {computed, onMounted} from 'vue';
import {compositions} from '@/compositions';

onMounted(() => api.loadModulesList());

const playerModules = computed(() => utils.sortBy(Game.playerModulesRecord?.modules, 'oid', true, true));

const allPlayerModuleOids = computed(() => Array.from(new Set(playerModules.value.map(pm => pm.oid))));

const modulesPresent = computed(() => utils.allKeysPresent(Game.modules, allPlayerModuleOids.value));

const relatedResearchIds = computed(() => {
  return modulesPresent.value ?
         allPlayerModuleOids.value
             .flatMap(id => {
               const module = Game.modules[id];
               return [module.researchId, module.ability?.researchId];
             })
             .filter(Boolean) :
      [];
});

compositions.researchesLoader(relatedResearchIds);
</script>
