import {Game, updateGameData} from '@/common';
import {fetchAuthSession} from '@aws-amplify/auth';
import {utils} from '@/helpers.js';

const GG_APP_ENDPOINT = window.location.hostname === 'localhost' ? 'http://localhost:8888/api/' : 'https://gg-api.dominioon.com:8888/api/';

async function getAuthHeader() {
  const session = await fetchAuthSession();
  return session.tokens?.idToken?.toString();
}

async function apiCall(path, type = 'get', body = null) {
  return fetch(GG_APP_ENDPOINT + path, {
    method: type,
    headers: {
      'content-type': 'application/json',
      Authorization: await getAuthHeader(),
    },
    body: body && JSON.stringify(body),
  })
    .then(res => res.json())
    .then(result => updateGameData(result))
    .catch((error) => {
      console.error(error);
      // alert('techError: ' + error);
    });
}

export const api = {
  get(path) {
    return apiCall(path);
  },

  post(path, body) {
    return apiCall(path, 'post', body);
  },

  del(path) {
    return apiCall(path, 'delete');
  },

  loadModulesList() {
    return api.get('modules');
  },

  loadNpcsList() {
    utils.removeExpiredEntries(Game.playerNpcs);
    return api.get('npcs');
  },

  loadColoniesList() {
    return api.get('colonies');
  },

  loadResearchProgresses() {
    return api.get('researches');
  },
};