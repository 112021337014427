<template>
  <div :class="rowClasses">
    <div class="row-image-container">
      <SmartImage :path="imagePath" :title="labels.name" :classes="imgClasses"/>
      <slot name="imageExtra"/>
    </div>

    <TitleBlock :labels="labels" :display="titleDisplay">
      <slot name="titleExtra"/>
      <template v-if="slots.titlePopupContent" #popupContent>
        <slot name="titlePopupContent"/>
      </template>
    </TitleBlock>

    <div class="row-content-container">
      <slot/>
    </div>

    <div v-if="slots.totals" class="row-totals-container">
      <slot name="totals"/>
    </div>

    <div v-if="slots.buttons" class="row-buttons-container -fc">
      <slot name="buttons"/>
    </div>
  </div>
</template>

<script setup>
import SmartImage from '@/components/utils/SmartImage';
import TitleBlock from '@/components/utils/TitleBlock';
import {computed, useSlots} from 'vue';

const slots = useSlots();

const props = defineProps(
    {
      rowClass: {type: String},
      imagePath: {type: String, required: true},
      imageClasses: {type: Array},
      labels: {type: Object, required: true},
      compact: {type: Boolean},
      titleDisplay: {type: String},
    },
);

const rowClasses = computed(() => {
  return [
    'list-row', '-rowlike', 'layout-general',
    props.compact ? 'layout-compact' : 'layout-wide',
    props.compact ? '-compact' : null,
    props.rowClass,
  ].filter(Boolean);
});

const imgClasses = computed(() => {
  const classes = ['row-image'];
  props.imageClasses && classes.push(...props.imageClasses);
  return classes;
});
</script>

<style>
.list-row {
  display: grid;
  row-gap: 2px;
  column-gap: 2px;
}

.row-image-container {
  grid-area: area-row-image;
  position: relative;
  align-self: start; /* for cases content is higher than image */
}

.row-image {
  display: block;
  max-width: 110px;
  border-radius: 3px;
  background: var(--color-b-94);
}

.title-block {
  grid-area: area-row-title;
}

.row-content-container {
  grid-area: area-row-content;
}

.row-buttons-container {
  grid-area: area-row-buttons;
  justify-self: end;
}

.row-buttons-container.-fc {
  align-items: flex-end; /* needed for buttons with uneven height */
}

.row-totals-container {
  grid-area: area-row-totals;

  font-weight: bold;
  font-size: 1.2rem;
  border: 1px solid var(--border);
  border-radius: 2px;
  padding: 2px;
}

@media screen and (min-width: 301px) {
  .list-row {
    justify-items: start;
    text-align: left; /* needed for popups */
  }

  .layout-compact {
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      "area-row-image area-row-title area-row-totals"
      "area-row-image area-row-content area-row-content"
      "area-row-image area-row-buttons area-row-buttons";
  }

  .layout-wide {
    grid-template-columns: min-content 1fr min-content min-content;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "area-row-image area-row-title area-row-title area-row-totals"
      "area-row-image area-row-content area-row-buttons area-row-buttons";
  }

  .row-totals-container {
    align-self: start;
  }
}

@media screen and (max-width: 300px) {
  .list-row {
    justify-items: center;
    text-align: center; /* needed for long texts */
  }

  .layout-general {
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content;
    grid-template-areas:
    "area-row-title area-row-title"
    "area-row-image area-row-image"
    "area-row-content area-row-content"
    "area-row-totals area-row-buttons";
  }

  .row-totals-container {
    align-self: center;
  }
}

</style>
