<template>
  <ArtiConcept v-if="type === 'ARTI'" :id="id" :arti="Game.player.artis[id] ?? {}"/>
  <ModuleConcept v-else-if="type === 'MODULE'" :id="id"/>
  <Race v-else-if="type === 'PR'" :race="Game.races[id]"/>
  <Region v-else-if="type === 'region'" :region="Game.regions[id]"/>
  <Research v-else-if="type === 'research'" :research="Game.researches[id]"/>

  <Row v-else-if="parts && parts.length" :image-path="icon" :labels="labels"
       title-display="full" row-class="concept">

    <template v-if="hasMaximum" #imageExtra>
      <RadialProgress :target="prop" :clockwise="false" :show-at-zero="true"
                      :start-color="barColor" :stop-color="barColor"
                      :size="83" :stroke-width="4" :inner-stroke-width="1.5"
                      :hints="{'top-right': 'clock', 'bottom-right': 'valueRemaining', 'top-left': 'max', 'bottom-left': 'value'}"/>
    </template>

    <template v-if="hasValue" #totals>
      <div v-text="propertyValue"/>
    </template>

    <div v-if="isHealthConcept">
      <AssignmentHint :assignment-id="healingAssignmentId" :target="Game.player.props.health"/>
    </div>

    <template #buttons v-if="isHealthConcept">
      <CrewAssigner v-if="isDamaged" api-path="players/current/heal"
                    :assignment-id="healingAssignmentId" button-label="Repair"/>
    </template>
  </Row>
</template>

<script setup>
import ArtiConcept from '@/components/ArtiConcept';
import Race from '@/components/Race';
import Region from '@/components/Region';
import Row from '@/components/utils/Row';
import ModuleConcept from '@/components/ModuleConcept';
import Research from '@/components/Research';
import CrewAssigner from '@/components/utils/CrewAssigner';
import AssignmentHint from '@/components/utils/AssignmentHint';
import RadialProgress from '@/components/utils/RadialProgress';
import {constants, utils} from '@/helpers';
import {Game} from '@/common';
import {computed} from 'vue';

const NO_VALUE = ['assist', 'forging', 'timed', 'toMax', 'limit', 'forged', 'improve', 'deteriorate', 'flags'];

const props = defineProps(
    {
      type: {type: String},
      id: {type: String},
      parts: {type: Array},
      labels: {type: Object},
      targetObject: {type: Object},
      noValue: {type: Boolean},
    },
);

const propertyConf = computed(() => constants.PROPERTY_CONFS[props.parts[1]] ?? {});

const icon = computed(() => `icons/${props.parts[2] ?? props.parts.join('/')}`); // NOSONAR

const prop = computed(() => utils.traverseSteps(props.targetObject ?? Game.player, props.parts));

const propertyValue = computed(() => {
  const value = typeof prop.value === 'object' ? prop.value.val : prop.value;
  return utils.formatNumber(value ?? 0, propertyConf.value.numberFormat);
});

const hasMaximum = computed(() => typeof prop.value === 'object' && prop.value.max);

const barColor = computed(() => propertyConf.value.color);

const isHealthConcept = computed(() => props.parts[1] === 'health');

const healingAssignmentId = computed(() => Game.player.props.health._id + '-' + Game.player._id);

const isDamaged = computed(() => {
  const health = Game.player.props.health;
  return health.val < health.max;
});

const hasValue = computed(() => !(props.noValue || NO_VALUE.includes(props.parts[0])));
</script>

<style>
.concept .row-image {
  width: 95px;
  height: 75px;
  margin: 8px;
  background: unset;
  filter: drop-shadow(1px 1px 1px var(--info));
}

.concept .row-image-container {
  border-radius: 3px;
  background: var(--color-b-94);
}
</style>