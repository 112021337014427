<template>
  <button type="button" :class="dealClasses" @click="action" :disabled="!canPerform">
    <span v-if="showAnything" class="deal-button-mutations">
      <span v-if="!canAffordTargetRequires" class="-targeted">
        <Mutations :list="targetDeal.requires" :target-object="targetObject" display="info"
                   :highlight-errors="true" :concept-display="conceptDisplay"/>
      </span>
      <Mutations v-if="computedShowRequires" :list="deal.requires" :progress="progress" :research-id="researchId"
                 :mutex-infos="mutexInfos" display="info" :highlight-errors="true" :concept-display="conceptDisplay"/>
      <Mutations v-if="computedShowUses" :list="deal.uses" :display="usageDisplay" :concept-display="conceptDisplay"/>
      <Mutations v-if="computedShowCosts" :list="deal.costs" display="attention"
                 :highlight-errors="true" :concept-display="conceptDisplay"/>
      <Mutations v-if="computedShowGains" :list="deal.gains" display="gain" :concept-display="conceptDisplay"/>
    </span>
    <span v-else-if="placeholderIcon" class="deal-button-mutations">
      <Mutations :list="[{p1: placeholderIcon, val: ''}]" display="info" :concept-display="conceptDisplay"/>
    </span>
    <span class="deal-content">
      <slot/>
    </span>
  </button>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      deal: {type: Object, required: true},
      targetDeal: {type: Object, default: () => ({})},
      targetObject: {type: Object},
      progress: {type: Object},
      researchId: {type: String},
      mutexInfos: {type: Array},
      action: {type: Function, required: true},
      showGains: {type: Boolean, default: false},
      alwaysShowRequires: {type: Boolean, default: false},
      usageDisplay: {type: String, default: 'info'},
      placeholderIcon: {type: String},
      disabled: {type: Boolean, default: false},
      classes: {type: Array},
    }
);

const { isResearchNeeded } = compositions.research(props.researchId);

const isInCooldown = computed(() => {
  const to = props.progress?.to;
  return to && (to > Game.now);
});

const hasMutexesAtLimit = computed(() => props.mutexInfos?.some(m => m.val >= m.max));

const preconditionsMet = computed(
    () => !props.disabled && !isInCooldown.value && !isResearchNeeded.value && !hasMutexesAtLimit.value);

const canAffordRequires = computed(() => utils.canAfford(props.deal.requires));

const computedShowRequires = computed(() => {
  if (!preconditionsMet.value) {
    return true;
  }
  return (props.alwaysShowRequires || !canAffordRequires.value) && utils.hasItems(props.deal.requires);
});

const computedShowUses = computed(() => utils.hasItems(props.deal.uses));

const computedShowCosts = computed(() => utils.hasItems(props.deal.costs));

const computedShowGains = computed(() => props.showGains && utils.hasItems(props.deal.gains) && !isInCooldown.value);

const showAnything = computed(
    () => computedShowRequires.value || computedShowUses.value || computedShowCosts.value || computedShowGains.value);

const canAffordCosts = computed(() => utils.canAfford(props.deal.costs));

const canAffordTargetRequires = computed(() => utils.canAfford(props.targetDeal.requires, props.targetObject));

const canAffordTargetCosts = computed(() => utils.canAfford(props.targetDeal.costs, props.targetObject));

const canPerform = computed(
    () => preconditionsMet.value &&
          canAffordCosts.value &&
          canAffordRequires.value &&
          canAffordTargetRequires.value &&
          canAffordTargetCosts.value);

const conceptDisplay = computed(() => canPerform.value ? 'off' : 'full');

const dealClasses = computed(() => {
  const result = ['deal'];
  props.classes && result.push(...props.classes);
  return result;
});
</script>

<style>
.deal-button-mutations {
  justify-content: center;
  height: 18px;
  margin-bottom: 2px;
  font-weight: normal;
}

@media screen and (max-width: 540px) {
  .-compact .deal-button-mutations {
    display: flex;
  }
}

@media screen and (max-width: 300px) {
  .deal-button-mutations {
    display: flex;
  }
}
</style>