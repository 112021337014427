<template>
  <Row :image-path="`missions/${id}.jpg`" :labels="labels" title-display="full">
    <DealConcept :title="Game.commonLabels.complete.name" :deal="{gains: props.mission.finishGains}"/>

    <DealConcept :title="withCount('round', props.mission.rounds)"
                 :deal="{gains: props.mission.roundGains}" :cooldown="props.mission.cooldown"/>

    <DealConcept v-for="(deal, ix) in props.mission.deals"
                 :title="ix === 0 ? withCount('step', props.mission.steps) : ''"
                 :descr="labels.steps ? `#${ix + 1}: ${labels.steps[ix]}` : null"
                 :deal="deal" :key="ix"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game} from '@/common';
import {compositions} from '@/compositions';

const props = defineProps(compositions.propDefs.mission);

const { id, labels } = compositions.mission(props);

function withCount(labelId, count) {
  return `${Game.commonLabels[labelId].name}: ${count ?? '∞'}`;
}
</script>
