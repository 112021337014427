<template>
  <Row :image-path="`modules/${id}.jpg`" :labels="labels" row-class="module-concept" title-display="full">
    <template #totals>
      <div v-text="count"/>
    </template>

    <DealConcept :title="Game.commonLabels.deal.name" :deal="{gains: moduleObject.gains, costs: decksMutations}"/>

    <DealConcept :title="Game.commonLabels.info.name" :deal="{}" :mutex-root="moduleObject"
                 :research-id="moduleObject.researchId" :forging-val="moduleObject.forgingVal"/>

    <DealConcept v-if="moduleObject.ability" :title="Game.commonLabels.ability.name" :deal="moduleObject.ability.deal"
                 :cooldown="moduleObject.ability.cooldown" :research-id="moduleObject.ability.researchId"/>

    <DealConcept :title="Game.commonLabels.shreds.name" :deal="{gains: moduleObject.shreds}"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      id: {type: String, required: true},
    }
);

const { moduleObject, labels } = compositions.module(props.id);

const decksMutations = computed(() => [{p1: 'props', p2: 'decks', val: moduleObject.value.size}]);

const count = computed(() => Game.playerModulesRecord?.modules.filter(m => m.oid === props.id).length);
</script>

<style>
.module-concept .row-image {
  max-width: 95px;
}
</style>