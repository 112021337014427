<template>
  <div :class="computedTitleClasses">
    <Popup :condition="showPopup">
      <template #content>
        <div v-if="!fullDisplay && !hasPopupSlot" class="descr" v-text="labels.descr"/>
        <slot name="popupContent"/>
      </template>
      <div v-if="slots.default">
        <div class="title">
          <span v-text="labels.name"/>
          <slot/>
        </div>
      </div>
      <div v-else class="title" v-text="labels.name"/>
      <div v-if="fullDisplay" class="descr" v-text="labels.descr"/>
    </Popup>
  </div>
</template>

<script setup>
import Popup from '@/components/utils/Popup';
import {computed, useSlots} from 'vue';

const slots = useSlots();

const props = defineProps(
    {
      labels: {type: Object, required: true},
      titleClasses: {type: Array},
      display: {type: String, validator(v) { return ['popup', 'full'].includes(v) }, default: 'popup'},
    }
);

const computedTitleClasses = computed(() =>  {
  const result = ['title-block'];
  props.titleClasses && result.push(...props.titleClasses);
  return result;
});

const hasPopupSlot = computed(() => !!slots.popupContent);

const showPopup = computed(() => hasPopupSlot.value || props.display === 'popup');

const fullDisplay = computed(() => props.display === 'full');
</script>

<style>
.title-block .title {
  font-weight: bold;
  font-size: 1.1rem;
}

.popper .title-block .title {
  font-size: 1rem;
}

.title-block .descr {
  flex: 0 0 100%;
  font-style: italic;
  margin-bottom: 3px;
  line-height: 1rem;
}

@media screen and (max-width: 540px) {
  .title-block .descr {
    font-size: 0.9rem;
    line-height: 0.85rem;
  }
}
</style>