<template>
  <div class="-content-only">
    <DealButton v-if="showReassign" :action="reassign" :deal="reassigningDeal">Reassign</DealButton>
    <DealButton v-if="isInProgress" :action="free" :deal="{gains: inProgressMutations}" :show-gains="true">Stop</DealButton>
    <DealButton v-else :action="assign" :deal="enhancedDeal" :research-id="researchId" :mutex-infos="mutexInfos"
                :always-show-requires="true">{{buttonLabel}}</DealButton>
  </div>
</template>

<script setup>
import {utils} from '@/helpers';
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      apiPath: {type: String, required: true},
      assignmentId: {type: String, required: true},
      buttonLabel: {type: String, default: 'Start'},
      deal: {type: Object},
      researchId: {type: String},
      mutexInfos: {type: Array},
    },
);

const {
  assignedEngineersCount,
  totalEngineersCount,
  idleEngineersCount,
  hasIdleEngineers,
  inProgressMutations,
  isInProgress,
  getEngineersMutation,
} = compositions.assignments(props.assignmentId);

const hasMutexesAtLimit = computed(() => props.mutexInfos?.some(m => m.val >= m.max));

const enhancedDeal = computed(() => {
  const result = props.deal ? utils.clone(props.deal) : {};
  if (utils.canAfford(result.requires) && utils.canAfford(result.costs) && !hasMutexesAtLimit.value) {
    result.requires = result.requires ?? [];
    result.requires.push(getEngineersMutation(idleEngineersCount.value ?? 1, hasIdleEngineers.value));
  }
  return result;
});

const showReassign = computed(() => isInProgress.value && totalEngineersCount.value > 1);

const reassigningDeal = {uses: [{p1: 'configure', val: ''}]};

function assign() {
  doPost();
}

function reassign() {
  const maxAvailable = idleEngineersCount.value + assignedEngineersCount.value;
  const count = window.prompt('How many should work?', maxAvailable);
  if (count === null) {
    return;
  }
  const toAdd = Number(count);
  if (toAdd || toAdd === 0) {
    doPost({count: toAdd});
  }
}

function free() {
  doPost({count: 0});
}

function doPost(body) {
  api.post(props.apiPath, body);
}
</script>
