<template>
  <img :src="guessPath" :alt="title" :title="title" :class="classes"/>
</template>

<script setup>
import {images} from '@/helpers';
import {computed} from 'vue';

const props = defineProps(
    {
      path: {type: String, required: true},
      title: {type: String, required: true},
      classes: {type: Array},
    }
);

const guessPath = computed(() => images.tryRealPath(props.path) ?? require('@/assets/unknown.svg'));
</script>

<style>
.row-image.avatar {
  max-width: 140px;
  border-radius: 50%;
}

.popper .row-image.avatar {
  max-width: 100px;
}

@media screen and (max-width: 540px) {
  .row-image.avatar {
    max-width: 110px;
  }
}

</style>
