<template>
  <Row :image-path="imagePath" :labels="labels" row-class="commodity" title-display="full">
    <div class="-fc -flex-wrap">
      <Mutations v-if="commodity.gains" :list="commodity.gains" display="gain"/>
      <Mutexes :mutex-root="commodity"/>
    </div>

    <template #buttons>
      <DealButton :action="buy" :deal="commodity">Buy</DealButton>
    </template>
  </Row>
</template>

<script setup>
import {images} from '@/helpers';
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import Mutexes from '@/components/Mutexes';
import Row from '@/components/utils/Row';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';

const props = defineProps(
    {
      commodity: {type: Object, required: true},
    }
);

const id = computed(() => props.commodity._id);

const imagePath = computed(() => images.getOrExtract(`commodities/${id.value}.jpg`, props.commodity.gains));

const labels = computed(() => Game.getLabels('commodities', id.value));

function buy() {
  api.post(`commodities/${id.value}`);
}
</script>
