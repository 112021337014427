<template>
  <div v-if="artisPresent" class="list">
    <Artifact v-for="(arti, id) in filteredArtis" :key="id" :arti="arti" :id="id"
              :target-id="props.targetId" :target-type="props.targetType"/>
  </div>
</template>

<script setup>
import Artifact from '@/components/Artifact';
import {utils} from '@/helpers';
import {api} from '@/api';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(compositions.propDefs.target);

const artisPresent = computed(() => utils.allKeysPresent(Game.artis, Object.keys(Game.player.artis)));

const filteredArtis = computed(() => {
  const result = {};
  Object.entries(Game.player.artis).forEach(([id, entry]) => {
    const shouldPass = entry.val && (!props.targetType || (props.targetType === Game.artis[id]?.targetType));
    shouldPass && (result[id] = entry);
  });
  return result;
});

const filteredPlayerArtiIds = computed(()=> Object.keys(filteredArtis.value));

const relatedResearchIds = computed(() => {
  return artisPresent.value ?
         filteredPlayerArtiIds.value
             .map(id => Game.artis[id].researchId)
             .filter(Boolean) :
      [];
});

onMounted(() => !artisPresent.value && api.get('artis'));

compositions.researchesLoader(relatedResearchIds);
</script>
