import {computed, ref} from 'vue';
import {Game} from '@/common';
import {api} from '@/api';
import {utils} from '@/helpers';

export const compositions = {
  crew() {
    const totalAssignedEngineersCount = computed(
      () => Object.values(Game.player.crew.engineers.assignments)
        .map(a => a.crewCount)
        .reduce((a, b) => a + b, 0));
    return {totalAssignedEngineersCount};
  },

  assignments(assignmentIdUnwrapped) {
    const {totalAssignedEngineersCount} = compositions.crew();
    const assignedEngineersCount = computed(
      () => Game.player.crew.engineers.assignments[assignmentIdUnwrapped]?.crewCount ?? 0);
    const totalEngineersCount = computed(() => Game.player.crew.engineers.val);
    const idleEngineersCount = computed(
      () => totalEngineersCount.value - totalAssignedEngineersCount.value);
    const hasIdleEngineers = computed(() => idleEngineersCount.value > 0);
    const inProgressMutations = computed(
      () => [getEngineersMutation(assignedEngineersCount.value)]);
    const isInProgress = computed(() => assignedEngineersCount.value > 0);

    function getEngineersMutation(count, permitted = false) {
      return {p1: 'crew', p2: 'engineers', val: count, permitted: permitted};
    }

    return {
      assignedEngineersCount,
      totalEngineersCount,
      idleEngineersCount,
      hasIdleEngineers,
      inProgressMutations,
      isInProgress,
      getEngineersMutation,
    };
  },

  research(researchIdUnwrapped) {
    const researchObj = computed(() => Game.researches[researchIdUnwrapped]);
    const researchProgressObj = computed(() => Game.playerResearches[researchIdUnwrapped]);
    const isResearchNeeded = computed(() => researchIdUnwrapped ? !researchProgressObj.value?.fin : false);
    const researchProgressVal = computed(() => researchProgressObj.value?.val ?? 0);
    const researchLeft = computed(() => researchObj.value?.val - researchProgressVal.value);
    return {researchProgressObj, isResearchNeeded, researchProgressVal, researchObj, researchLeft};
  },

  region() {
    const movementTask = computed(() => Game.player.tasks.find(t => t._id === Game.gameConf.ids.movementTask));
    const targetRegionId = computed(() => Game.movementInProgress ?
                                          movementTask.value.impact.find(i => i.p1 === 'regionId').val :
                                          undefined);
    return {movementTask, targetRegionId};
  },

  module(moduleIdUnwrapped) {
    const moduleObject = computed(() => Game.modules[moduleIdUnwrapped]);
    const labels = computed(() => Game.getLabels('modules', moduleIdUnwrapped));
    return {moduleObject, labels};
  },

  // propDefs.mission
  mission(props) {
    const id = computed(() => props.mission._id);
    const labels = computed(() => Game.getLabels('missions', id.value));
    return {id, labels};
  },

  // propDefs.arti
  arti(props) {
    const realArti = computed(() => Game.artis[props.id]);
    const labels = computed(() => Game.getLabels('artis', props.id));
    const amount = computed(() => props.arti.val ?? 0);
    const displayAmount = computed(() => utils.formatNumber(amount.value, 'full'));
    const researchId = computed(() => realArti.value.researchId);
    const mutexRoot = realArti;
    return {realArti, labels, amount, displayAmount, researchId, mutexRoot};
  },

  mux() {
    const mutexInfos = ref(null);
    const hasMutexesAtLimit = computed(() => mutexInfos.value?.some(m => m.val >= m.max));
    const updateMutexInfos = (mi) => mutexInfos.value = mi;
    return {mutexInfos, hasMutexesAtLimit, updateMutexInfos};
  },

  colony() {
    const accessibleColoniesList = computed(() => utils.sortBy(Game.playerColoniesRecord?.colonies ?? [], 'createdAt', true));
    const ownedColoniesCount = computed(() => accessibleColoniesList.value.filter(c => c.owned).length);
    const maxColoniesCount = computed(() => Math.ceil(Game.player.level / Game.gameConf.multipliers.levelsPerColony));
    return {accessibleColoniesList, ownedColoniesCount, maxColoniesCount};
  },

  // propDefs.target
  target(props) {
    const targetObject = computed(() => {
      return props.targetType === 'npc' ?
             (Game.playerNpcs[props.targetId] ?? null) :
             null; // DealConcept expects it to be explicitly null
    });
    return {targetObject};
  },

  researchesLoader(relatedResearchIdsWrapped) {
    const relatedResearchesPresent = computed(
      () => utils.allKeysPresent(Game.playerResearches, relatedResearchIdsWrapped.value));

    utils.watchAndTrigger(relatedResearchesPresent, () => !relatedResearchesPresent.value && api.loadResearchProgresses());

    return {relatedResearchesPresent};
  },

  // propDefs.numbers
  numbers(props) {
    function formatNumbers(values, format = undefined) {
      const formatToUse = format ?? props.numberFormat;
      const formatter = (value) => utils.formatNumber(value, formatToUse);
      return Array.isArray(values) ? values.map(formatter).join('') : formatter(values);
    }
    return {formatNumbers};
  },

  propDefs: {
    mission: {
      mission: {type: Object, required: true},
    },
    target: {
      targetId: {type: String},
      targetType: {type: String, validator(v) { return ['npc'].includes(v) }},
    },
    arti: {
      arti: {type: Object, required: true},
      id: {type: String, required: true},
    },
    numbers: {
      numberFormat: {type: String, validator(v) { return ['compact', 'full'].includes(v) }, default: 'compact'},
    },
  },
};
