<template>
  <div style="display: none">
    <Ticker v-for="(prop, key) in Game.player.props" :target="prop" :key="key + '-' + Game.player._id"
            :on-fulfil="key === 'health' ? refreshPlayer : undefined"/>
    <Ticker v-for="t in Game.player.tasks" :target="t" :on-fulfil="refreshPlayer" :key="t._id"/>
    <Ticker v-for="t in ownedModulesTasks" :target="t" :on-fulfil="loadModulesList" :key="t._id"/>
    <Ticker v-for="temp in Game.player.temps" :target="temp" :on-fulfil="refreshPlayer" :key="JSON.stringify(temp)"/>

    <div v-for="(npc, id) in Game.playerNpcs" :key="id">
      <Ticker v-for="(prop, key) in npc.props" :target="prop" :key="id + '-' + key"/>
      <Ticker v-for="temp in npc.temps" :target="temp" :on-fulfil="() => refreshNpc(id)" :key="JSON.stringify(temp)"/>
      <Ticker :target="npc" :on-fulfil="loadNpcsList" :key="id"/>
    </div>
  </div>
</template>

<script setup>
import Ticker from '@/components/utils/Ticker';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';

const ownedModulesTasks = computed(() => Object.values(Game.ownedModulesRecords).flatMap(r => r.tasks));

function refreshPlayer() {
  return api.get('players/current');
}

function loadNpcsList() {
  return api.loadNpcsList();
}

function refreshNpc(id) {
  return api.get(`npcs/${id}`);
}

function loadModulesList() {
  api.loadModulesList();
}
</script>
