<template>
  <div class="list">
    <Colony v-for="colony in accessibleColoniesList" :key="colony._id" :accessibleColony="colony"/>
  </div>
</template>

<script setup>
import Colony from '@/components/Colony.vue';
import {api} from '@/api';
import {onMounted} from 'vue';
import {compositions} from '@/compositions';

const {accessibleColoniesList} = compositions.colony();

onMounted(() => api.loadColoniesList());
</script>
