import {createApp} from 'vue';
import GameWrapper from '@/components/GameWrapper';
import {Amplify} from 'aws-amplify'
import config from './amplifyconfiguration.json';

Amplify.configure(config);

const app = createApp(GameWrapper);

app.mount('#game-root');
