<template>
  <Row v-if="moduleObject" :image-path="`modules/${id}.jpg`" :image-classes="imageClasses"
       :labels="labels" row-class="module" :compact="true">
    <template #imageExtra>
      <RadialProgress v-if="task" :target="task" :classes="progressBarClasses"
                      :hints="{'top-right': 'clock', 'bottom-right': 'valueRemaining', 'top-left': 'max', 'bottom-left': 'value'}"/>
    </template>

    <template #titlePopupContent>
      <ModuleConcept :id="id"/>
    </template>

    <div class="-fc -flex-wrap">
      <div :class="gainMutationsClasses">
        <Mutations :list="moduleObject.gains" display="gain"/>
      </div>
      <div :class="costMutationsClasses">
        <Mutations :list="decksMutations" display="attention"/>
      </div>
      <Mutexes :mutex-root="moduleObject" :infos-callback="updateMutexInfos"/>
      <AssignmentHint :assignment-id="uid" :target="task"/>
    </div>

    <template #buttons>
      <DealButton v-if="ability" :action="useAbility" :deal="ability.deal" :progress="module.ability" :show-gains="true"
                  :research-id="ability.researchId" :disabled="!isInstalled">Use ability</DealButton>
      <CrewAssigner v-if="canForge" :api-path="apiPath" :assignment-id="uid + '-' + Game.player._id" :deal="forgingDeal"
                    :mutex-infos="forgingButtonMutexInfos" :button-label="forgingButtonLabel"
                    :research-id="moduleObject.researchId"/>
      <DealButton v-if="isUninstalled" :action="install" :deal="{costs: decksMutations}">Install</DealButton>
      <DealButton v-if="isInstalled" :action="uninstall" :deal="{gains: decksMutations}" :show-gains="true">Uninstall</DealButton>
      <DealButton v-if="utils.hasItems(moduleObject.shreds)" :action="shred" :deal="{gains: shredMutations}" :show-gains="true">Shred</DealButton>
    </template>
  </Row>
</template>

<script setup>
import {utils} from '@/helpers';
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import RadialProgress from '@/components/utils/RadialProgress';
import Mutexes from '@/components/Mutexes';
import Row from '@/components/utils/Row';
import CrewAssigner from '@/components/utils/CrewAssigner';
import AssignmentHint from '@/components/utils/AssignmentHint';
import ModuleConcept from '@/components/ModuleConcept';
import {api} from '@/api';
import {Game} from '@/common';
import {computed, ref} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      module: {type: Object, required: true},
    }
);

const abilityUsed = ref(false);

const id = computed(() => props.module.oid);

const { mutexInfos, hasMutexesAtLimit, updateMutexInfos } = compositions.mux();

const { moduleObject, labels } = compositions.module(id.value);

const uid = computed(() => props.module._id);

const task = computed(() => Game.playerModulesRecord?.tasks.find(t => t._id === uid.value));

const state = computed(() => props.module.state);

const isInstalled = computed(() => state.value === 'on');

const isUninstalled = computed(() => state.value === 'off');

const isUsingDecks = computed(() => state.value === 'on' || state.value === 'forging');

const isForgingInProgress = computed(() => state.value === 'forging' && task.value?.uph > 0);

const isNotStarted = computed(() => state.value === 'parts');

const canForge = computed(() => state.value === 'parts' || state.value === 'forging');

const imageClasses = computed(
    () => addDisabledIf(abilityUsed.value ? ['animation-glowing', '-animation-fast', '-animation-once'] : [], !isInstalled.value));

const progressBarClasses = computed(() => addDisabledIf([], !isForgingInProgress.value));

const gainMutationsClasses = computed(() => addDisabledIf([], !isInstalled.value));

const costMutationsClasses = computed(() => addDisabledIf([], !isUsingDecks.value));

const decksMutations = computed(() => {
  const decks = Game.player.props.decks;
  const size = moduleObject.value.size;
  return [{
    p1: 'props', p2: 'decks', val: size,
    permitted: decks.max - decks.val >= size,
  }];
});

const forgingDeal = computed(() => {
  const costs = isNotStarted.value && !hasMutexesAtLimit.value ? decksMutations.value : [];
  return {costs: costs};
});

const ability = computed(() => moduleObject.value.ability);

const shredMutations = computed(() => {
  let shreds = moduleObject.value.shreds;
  if (shreds && (isInstalled.value || isUninstalled.value)) {
    shreds = utils.clone(shreds);
    shreds.forEach(s => s.val = Math.floor((s.val ?? 1) * Game.gameConf.multipliers.forgedModuleShred));
  }
  return shreds;
});

const apiPath = computed(() => `modules/${uid.value}/forge`);

const forgingButtonMutexInfos = computed(() => isNotStarted.value ? mutexInfos.value : null);

const forgingButtonLabel = computed(() => isNotStarted.value ? 'Build' : 'Resume');

function install() {
  doPost('install');
}

function uninstall() {
  doPost('uninstall');
}

function useAbility() {
  doPost('useAbility');
  abilityUsed.value = true;
  window.setTimeout(() => abilityUsed.value = false, 3000);
}

function shred() {
  if (window.confirm(`Really shred ${labels.value.name}?`)) {
    api.del(`modules/${uid.value}`);
  }
}

function doPost(path) {
  api.post(`modules/${uid.value}/${path}`);
}

function addDisabledIf(classes, isDisabled) {
  isDisabled && classes.push('-disabled');
  return classes;
}
</script>
