<template>
  <Row :image-path="`icons/artis/${props.id}.svg`" :labels="labels"
       row-class="arti" title-display="full">

    <template #totals>
      <div v-text="displayAmount"/>
    </template>

    <DealConcept :title="Game.commonLabels.deal.name" :deal="realArti"/>

    <DealConcept v-if="realArti.targetDeal" :target-object="null" :deal="realArti.targetDeal"/>

    <DealConcept :title="Game.commonLabels.info.name" :deal="{}" :mutex-root="mutexRoot"
                 :cooldown="realArti.cooldown" :research-id="researchId"/>

    <DealConcept :title="Game.commonLabels.shreds.name" :deal="{gains: realArti.shreds}"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game} from '@/common';
import {compositions} from '@/compositions';

const props = defineProps(compositions.propDefs.arti);

const { realArti, labels, displayAmount, researchId, mutexRoot } = compositions.arti(props);
</script>
