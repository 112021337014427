<template>
  <Row :image-path="`icons/artis/${props.id}.svg`" :labels="labels"
       :image-classes="imageClasses" row-class="arti" title-display="full">

    <template #titlePopupContent>
      <ArtiConcept :id="props.id" :arti="props.arti"/>
    </template>

    <template #totals>
      <div v-text="displayAmount"/>
    </template>

    <div class="-fc -flex-wrap">
      <Mutations :list="realArti.gains" display="gain"/>
      <Mutexes :mutex-root="mutexRoot" :target-object="targetObject" :infos-callback="updateMutexInfos"/>
    </div>
    <div v-if="realArti.targetDeal" class="-fc -flex-wrap">
      <DealConcept :target-object="targetObject" :deal="realArti.targetDeal"/>
    </div>

    <template #buttons>
      <DealButton v-if="canUse" :action="use" :progress="props.arti" :research-id="researchId"
                  :deal="realArti" :target-deal="realArti.targetDeal" :target-object="targetObject"
                  :mutex-infos="mutexInfos" placeholder-icon="effect">Use</DealButton>
      <DealButton v-if="utils.hasItems(realArti.shreds)" :action="shred" :deal="{gains: realArti.shreds}" :show-gains="true">Shred</DealButton>
    </template>
  </Row>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import {utils} from '@/helpers';
import Mutexes from '@/components/Mutexes';
import Row from '@/components/utils/Row';
import ArtiConcept from '@/components/ArtiConcept';
import DealConcept from '@/components/utils/DealConcept';
import {api} from '@/api';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      ...compositions.propDefs.arti,
      ...compositions.propDefs.target,
    },
);

const { realArti, labels, amount, displayAmount, researchId, mutexRoot } = compositions.arti(props);

const { mutexInfos, updateMutexInfos } = compositions.mux();

const { isResearchNeeded } = compositions.research(researchId.value);

const { targetObject } = compositions.target(props);

const canUse = computed(() => props.targetType === realArti.value.targetType);

const imageClasses = computed(() => isResearchNeeded.value ? ['-disabled'] : null);

function use() {
  const target = props.targetId ? `/at/${props.targetId}` : '';
  api.post(`artis/${props.id}${target}`);
}

function shred() {
  const initial = String(Math.max(amount.value - 1, 1));
  const shredCount = Number(window.prompt(`${labels.value.name}:\nHow many to shred?`, initial));
  if (shredCount > 0) {
    api.del(`artis/${props.id}/${shredCount}`);
  }
}
</script>

<style>
.arti .row-image {
  max-width: 65px;
  padding: 5px;
}
</style>