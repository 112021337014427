<template>
  <Concepts v-if="displayer.exists()" :list="displayer.concepts()" :display="conceptDisplay">
    <div :class="mutationClasses">
      <template v-for="e in displayer.allElements()">
        <Icon v-if="e.path"  :path="e.path" :title="e.title" :display="computedDisplay" :key="String(e.path)"/>
        <div  v-if="e.text" v-text="e.text" :title="e.title" class="mutation-text" :key="String(e.text)"/>
      </template>
      <slot/>
    </div>
  </Concepts>
</template>

<script setup>
import Icon from '@/components/utils/Icon';
import {utils} from '@/helpers';
import {computed, defineAsyncComponent} from 'vue';
import {
  Arti,
  Displayer,
  Module,
  Region,
  Relationship,
  Research,
  Resource,
  UniqueDisplayer,
} from '@/components/utils/mutationClasses';

const Concepts = defineAsyncComponent(() => import('@/components/utils/Concepts'));

const props = defineProps(
    {
      mutation: {type: Object, required: true},
      display: {type: String, validator(v) { return ['gain', 'attention', 'error', 'info'].includes(v) }},
      highlightErrors: {type: Boolean},
      conceptDisplay: {type: String},
      targetObject: {type: Object},
    }
);

const computedDisplay = computed(() => props.highlightErrors && !utils.canAfford([props.mutation]) ? 'error' : props.display);

const mutationClasses = computed(() => {
  const extra = ['number', 'undefined'].includes(typeof props.mutation.val) ? 'numeric' : 'non-numeric';
  const classes = ['mutation', extra];
  computedDisplay.value && classes.push('-' + computedDisplay.value);
  return classes;
});

const displayer = (() => {
  switch (props.mutation.sc) {
    case 'PR':
      return new Relationship(props);
    case 'MODULE':
      return new Module(props);
    case 'ARTI':
      return new Arti(props);
    default: {
      switch (props.mutation.p1) {
        case 'regionId':
          return new Region(props);
        case 'resources':
          return new Resource(props);
        case 'talents':
        case 'flags':
          return new UniqueDisplayer(props, props.mutation.p1);
        case 'lab':
          return new Research(props, computedDisplay);
      }
      return new Displayer(props);
    }
  }
})();
</script>

<style>
.mutation {
  display: flex;
  align-items: center;
  margin: 0 1px;
  border: 1px solid var(--mutation-border);
  border-radius: 5px;
  color: var(--text);
  background-color: var(--mutation-background);
}

.mutation.non-numeric {
  font-size: 0.95rem;
}

.mutation.numeric {
  white-space: nowrap;
}

.mutation.numeric.-attention .mutation-text:before {
  content: '-';
}

.mutation-text, .mutation-text + .icon {
  margin-left: 3px;
}

@media screen and (min-width: 541px) {
  .mutation {
    padding: 2px 3px;
  }
}

@media screen and (max-width: 540px) {
  .mutation {
    padding: 1px 2px;
  }
}
</style>
