import {reactive} from 'vue';
import {useEventBus} from '@vueuse/core';

function mergeGameProperty(key, entry) {
  const target = Game[key];
  const isArray = Array.isArray(target);
  Object.entries(entry).forEach(([id, value]) => {
    if (isArray) {
      mergeArray(target, id, value);
    }
    else {
      mergeObject(target, id, value);
    }
  });
}

function mergeArray(array, id, value) {
  let index = array.findIndex(e => e._id === id);
  if (index === -1) {
    // index = array.length;
    return;
  }
  if (value === null) {
    array.splice(index, 1);
  }
  else {
    array[index] = value;
  }
}

function mergeObject(object, id, value) {
  if (value === null) {
    delete object[id];
  }
  else {
    object[id] = value;
  }
}

function handleVersion(version) {
  if (Game.ver === null) {
    Game.ver = version;
  }
  else if (Game.ver !== version) {
    window.location.reload();
  }
}

export const Game = reactive(
  {
    accessibleColoniesRecords: {},
    playerId: null,
    gameConf: null,
    labels: {},
    regions: {},
    players: {},
    missions: {},
    playerMissions: {},
    artis: {},
    modules: {},
    ownedModulesRecords: {},
    colonySpecs: {},
    commodities: {},
    colonies: {},
    researches: {},
    playerResearches: {},
    npcs: {},
    playerNpcs: {},
    mutexes: {},
    races: null,
    timeDiff: 0,
    now: Date.now(),
    ver: null,
    get player() {
      return Game.players[Game.playerId];
    },
    get playerModulesRecord() {
      return Game.ownedModulesRecords[Game.playerId];
    },
    get playerColoniesRecord() {
      return Game.accessibleColoniesRecords[Game.playerId];
    },
    get commonLabels() {
      return Game.labels.commons;
    },
    getLabels(key, id) {
      return Game.labels[key]?.[id] ?? {};
    },
    get movementInProgress() {
      return Game.player &&
             Game.gameConf &&
             Game.player.regionId === Game.gameConf.ids.inMovementRegion;
    },
  },
);

export const updateGameData = (json) => {
  Object.entries(json).forEach(([key, value]) => {
    switch (key) {
      case 'playerId':
      case 'players':
      case 'colonySpecs':
      case 'gameConf':
      case 'races':
        Game[key] = value;
        break;

      case 'accessibleColoniesRecords':
      case 'regions':
      case 'artis':
      case 'commodities':
      case 'colonies':
      case 'modules':
      case 'npcs':
      case 'missions':
      case 'ownedModulesRecords':
      case 'researches':
      case 'playerNpcs':
      case 'playerMissions':
      case 'playerResearches':
      case 'mutexes':
        mergeGameProperty(key, value);
        break;

      case 'labels':
        Object.entries(value).forEach(([labelKey, labelValues]) => {
          Game.labels[labelKey] = {...Game.labels[labelKey], ...labelValues};
        });
        break;

      case 'now':
        Game.timeDiff = value - Date.now();
        break;

      case 'gains':
        // todo: implement
        break;

      case 'errors':
        if (value.length) {
          alert(value.map(e => JSON.stringify(e)).join('\n'));
        }
        break;

      case 'ver':
        handleVersion(value);
        break;

      default:
        console.warn('Unknown json entry ', key, value);
        break;
    }
  });
};

export const modalsBus = useEventBus('modals');

export const regionBus = useEventBus('region');

export const storage = reactive({
  modals: {
    nextZIndex: 1,
    stack: {},
  },
});
