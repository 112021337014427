<template>
  <Row :image-path="`colonies/${oid}.png`" :labels="labels" row-class="colony" :image-classes="imgClasses">
    <Mutations :list="colony.impact" :target-object="colony" display="gain"/>
    <div class="mutations">
      <Mutation :mutation="sizeMutation" display="info"/>
      <Mutation :mutation="regionMutation" :display="regionDisplay"/>
      <Mutation :mutation="ownerMutation" :display="ownerDisplay" concept-display="valueless"/>
    </div>

    <template #buttons>
      <DealButton :action="colonize" :deal="colonizingDeal" v-if="!accessibleColony.owned">Colonize</DealButton>
    </template>
  </Row>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations.vue';
import Row from '@/components/utils/Row.vue';
import {computed} from 'vue';
import {Game} from '@/common';
import DealButton from '@/components/utils/DealButton.vue';
import {api} from '@/api';
import {utils} from '@/helpers';
import Mutation from '@/components/utils/Mutation.vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      accessibleColony: {type: Object, required: true},
    },
);

const {ownedColoniesCount, maxColoniesCount} = compositions.colony();

const colony = computed(() => Game.colonies[props.accessibleColony._id]);

const oid = computed(() => colony.value.oid);

const sizeMutation = computed(() => ({p1: 'size', val: colony.value.size}));

const regionMutation = computed(() => ({p1: 'regionId', val: colony.value.regionId}));

const regionDisplay = computed(() => colony.value.regionId === Game.player.regionId ? 'info' : 'attention');

const ownerMutation = computed(() => ({p1: 'ownership', p2: colony.value.owner ? 'you' : 'nobody', val: ''}));

const ownerDisplay = computed(() => {
  if (!colony.value.owner) {
    return 'gain';
  }
  return colony.value.owner.id === Game.playerId ? 'info' : 'attention';
});

const imgClasses = computed(() => props.accessibleColony.createdAt + 5000 > Date.now() ? // no need to use dynamic Game.now
    ['animation-fading-in', '-animation-fastest', '-animation-once'] :
    undefined
);

const sizeCss = computed(() => (Math.min(120, 20 + 10 * colony.value.size)) + 'px');

const labels = computed(() => ({
  name: colony.value.name,
  descr: Game.getLabels('colonySpecs', oid.value).descr,
}));

const colonizingDeal = computed(() => {
  const deal = utils.clone(Game.gameConf.deals.colonyCapture);
  !deal.requires && (deal.requires = []);
  deal.requires.push(regionMutation.value);
  (ownedColoniesCount.value >= maxColoniesCount.value) && deal.requires.push({p1: 'limit', val: maxColoniesCount.value});
  return deal;
});

function colonize() {
  api.post(`colonies/${props.accessibleColony._id}/colonize`);
}
</script>

<style>
.colony .row-image-container {
  display: flex;
  width: 120px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.colony .row-image {
  background: unset;
  max-width: unset;
  max-height: v-bind(sizeCss);
}

</style>