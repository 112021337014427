<template>
  <div class="-content-only">
    <Mutations :list="mutexesListFor(infosForSelf)" display="info"/>
    <div v-if="infosForTarget.length" class="-targeted">
      <Mutations :list="mutexesListFor(infosForTarget)" display="info"/>
    </div>
  </div>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';
import {utils} from '@/helpers';

const mutexConfigs = {
  'MODULE': {
    getMutexed: (rootObj, mutexId) => {
      if (rootObj._id !== Game.player._id) {
        return [];
      }
      // todo: should it be generic or playerModulesRecord?
      return Game.playerModulesRecord?.modules.filter(om => Game.modules[om.oid].mutexId === mutexId);
    },
    filter: e => e.state !== 'parts',
    textCreator: (i, name) => withSuffix(i, name, `(${i.val}/${i.total} on)`),
  },
  'TEMP': {
    getMutexed: (rootObj, mutexId) => rootObj.temps.filter(t => t.mutexId === mutexId),
    filter: () => true,
    textCreator: (i, name) => withSuffix(i, name, `(${i.val} on)`),
  },
};

const props = defineProps(
    {
      mutexRoot: {type: Object, required: true},
      targetObject: {type: Object},
      infosCallback: {type: Function},
    }
);

const infosForSelf = computed(() => {
  const root = props.mutexRoot;
  const ids = [root.mutexId];
  root.gains
      ?.flatMap(g => getMutexId(g))
      .forEach(id => ids.push(id));
  return ids
      .filter(Boolean)
      .map(id => toMutexInfo(id, Game.player));
});

const infosForTarget = computed(() => {
  return props.mutexRoot.targetDeal?.gains
             ?.flatMap(g => getMutexId(g))
             .filter(Boolean)
             .map(id => toMutexInfo(id, props.targetObject)) ??
      [];
});

const mutexInfos = computed(() => [...infosForSelf.value, ...infosForTarget.value]);

onMounted(() => {
  if (props.infosCallback) {
    utils.watchAndTrigger(mutexInfos, () => props.infosCallback(mutexInfos.value));
  }
});


function mutexesListFor(infos) {
  return infos.map(i => ({p1: 'limit', val: i.text}));
}

function getMutexId(mutex) {
  if (mutex.mutexId) {
    return mutex.mutexId;
  }
  else if (mutex.sc === 'MODULE') {
    return Game.modules[mutex.id].mutexId;
  }
  return undefined;
}

function toMutexInfo(id, targetObj) {
  const mutex = Game.mutexes[id];
  const config = mutexConfigs[mutex.type];
  const info = (() => {
    if (!targetObj) {
      return {val: 0, total: 0};
    }
    const total = config.getMutexed(targetObj, mutex._id);
    return {
      val: total.filter(config.filter).length,
      total: total.length,
    };
  })();
  info.max = mutex.val;
  info.text = config.textCreator(info, Game.getLabels('mutexes', id).name);
  return info;
}

function withSuffix(i, name, suffix) {
  const toAppend = i.total > 0 ? ` ${suffix}` : '';
  return `${i.max} ${name}${toAppend}`;
}
</script>
